import { Tooltip } from "react-bootstrap";


export const clearFilterTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Clears filter and loads all in-progress work periods.
    </Tooltip>
);

export const summaryTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Poduces spreadsheet with daily hour totals.
    </Tooltip>
);

export const adjustmentTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Generates recommended adjustments, but ignores in-progress work periods.
    </Tooltip>
);

export const timesheetTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Generates a spreadsheet with data as displayed in AddisonOp.
    </Tooltip>
);

export const fishyTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Generates adjustment report that only shows fishy time periods. NOT FOOLPROOF.
    </Tooltip>
);

export const quickbooksTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Generates a csv file formatted for QuickBooks Time import.
    </Tooltip>
);

export const absentTT = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Generates a csv file with a list of employees that did not clock in during the given date range. Press "Apply Filter" first to get accurate results.
    </Tooltip>
);