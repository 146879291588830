import 'bootstrap/dist/css/bootstrap.min.css';
import AddUser from './AddUser';

const Admin = ({ client, user, userInfo }) => {

  if (user && userInfo.user_level === 1) {
    return (
      <>
        <div className='=App'>
          <AddUser client={client} user={user} userInfo={userInfo} />
        </div>
      </>
    );
  } else {
    return (
      <div>
          Access denied.
      </div>
    );
  };
};

export default Admin;