import moment from 'moment';

export function csvAbsences (wps, workers) {

    const csvRows = [];
    const notImp = [
        "Neil Munro", "Vernon Gist", "Terresa Holmes",
        "Robert Dorr", "Rachael Hudson", "Laura Brimhall", 
        "Elizabeth Gist", "Dori Norcross", "Bridget Woods",
        "Greg Rutledge", "Ricky Ellis", "Danielle Beard", "Alisha Hinchey",
        "Evan Munro"
    ];
    const workersActive = workers.filter(w => w.active === true);
    const workersMatter = workersActive.filter(w => {
        let name = w.first_name + " " + w.last_name;
        let found = notImp.find(n => n === name);
        if (found) {
            return false;
        } else {
            return true;
        }
    });
    const dates = [...new Set(wps.map(wp => wp.punch_in.slice(0,10)))];

    dates.sort();

    for (const date of dates) {
        csvRows.push("*****" + date + "*****");
        for (const wurker of workersMatter) {
            let name = wurker.first_name + " " + wurker.last_name;
            let found = wps.find(wp => (wp.employee.id === wurker.id && wp.punch_in.slice(0,10) === date));
            if (found) {
                continue;
            } else {
                csvRows.push(name);
            }
        }
    }
    
    return csvRows.join('\n');
}
