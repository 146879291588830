
export function summaryFunc (data) {

    const clone = JSON.parse(JSON.stringify(data));
    const clone2 = JSON.parse(JSON.stringify(data));

    var individualResult = clone.reduce(function(res, obj) {
        if (!(obj.employee__id in res)) {
            res.__array.push(res[obj.employee__id] = obj);
            const dt = obj.wp_sum.split(':');
            let seconds = (+dt[0]) * 60 * 60 + (+dt[1]) * 60 + (+dt[2] || 0);
            let hours = Math.round((seconds / (60*60)) * 100) / 100
            res[obj.employee__id].wp_sum = hours;
        } else {
            const dt = obj.wp_sum.split(':');
            let seconds = (+dt[0]) * 60 * 60 + (+dt[1]) * 60 + (+dt[2] || 0);
            let hours = Math.round((seconds / (60*60)) * 100) / 100
            res[obj.employee__id].wp_sum += hours;
        }
        return res;
    }, {__array:[]}).__array.sort(function(a,b) { return b.wp_sum - a.wp_sum; });

    var departmentResult = clone2.reduce(function(res, obj) {
        if (!(obj.employee__department in res)) {
            res.__array.push(res[obj.employee__department] = obj);
            const dt = obj.wp_sum.split(':');
            let seconds = (+dt[0]) * 60 * 60 + (+dt[1]) * 60 + (+dt[2] || 0);
            let hours = Math.round((seconds / (60*60)) * 100) / 100
            res[obj.employee__department].wp_sum = hours;
        } else {
            const dt = obj.wp_sum.split(':');
            let seconds = (+dt[0]) * 60 * 60 + (+dt[1]) * 60 + (+dt[2] || 0);
            let hours = Math.round((seconds / (60*60)) * 100) / 100
            res[obj.employee__department].wp_sum += hours;
        }
        return res;
    }, {__array:[]}).__array.sort(function(a,b) { return b.wp_sum - a.wp_sum; });

    const clone3 = JSON.parse(JSON.stringify(departmentResult));
    var factoryResult = clone3.reduce((partialSum, a) => partialSum + a.wp_sum, 0);

    const csvRows = [];
    const headers = ["id","name","employee__department","punch_in__date","wp_sum"]
    csvRows.push(headers.join(','));

    let index = 0;
    for (const row of data) {
        if ((index > 0 || index === data.length - 1) && data.length != 1){
            if (row["employee__id"] !== data[index-1].employee__id){
                let match = individualResult.filter((res) => res.employee__id === data[index-1].employee__id)[0];
                const values = [`""`,`""`, `""`, `""`, `""`, `"${match.employee__first_name} ${match.employee__last_name} ===> ${match.wp_sum}"`];
                csvRows.push(values.join(','));
            }
            if (row["employee__department"] !== data[index-1].employee__department){
                let match = departmentResult.filter((res) => res.employee__department === data[index-1].employee__department)[0];
                const values = [`""`,`""`, `""`, `""`, `""`, `""`, `""`, `"${match.employee__department} ===> ${match.wp_sum}"`];
                csvRows.push(values.join(','));
            }
        } 

        const values = headers.map(header => {
            if (header === "id") {
                const val = row["employee__barcode_id"];
                return `"${val}"`;
            } else if (header === "name") {
                const val = row["employee__first_name"]+" "+row["employee__last_name"];
                return `"${val}"`;
            } else if (header === "wp_sum") {
                const dt = row[header].split(':');
                let seconds = (+dt[0]) * 60 * 60 + (+dt[1]) * 60 + (+dt[2] || 0);
                let hours = Math.round((seconds / (60*60)) * 100) / 100;
                return `"${hours}"`;
            } else {
                const val = row[header];
                return `"${val}"`;
            }
        });
        csvRows.push(values.join(','));

        if (index === data.length - 1){
            let matchEmployee = individualResult.filter((res) => res.employee__id === data[index].employee__id)[0];
            const valuesEmp = [`""`,`""`, `""`, `""`, `""`, `"${matchEmployee.employee__first_name} ${matchEmployee.employee__last_name} ===> ${matchEmployee.wp_sum}"`];
            csvRows.push(valuesEmp.join(','));
            let matchDept = departmentResult.filter((res) => res.employee__department === data[index].employee__department)[0];
            const valuesDept = [`""`,`""`, `""`, `""`, `""`, `""`, `""`, `"${matchDept.employee__department} ===> ${matchDept.wp_sum}"`];
            csvRows.push(valuesDept.join(','));
            const valuesFactory = [`""`,`""`, `""`, `""`, `""`, `""`, `""`, `""`, `""`, `"Factory Total ===> ${factoryResult}"`];
            csvRows.push(valuesFactory.join(','));
        }

        index++;
    }
   
    return csvRows.join('\n');
}
