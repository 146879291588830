import { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';
import { summaryFunc } from "./tools/summaryAbs";
import { absToCSV } from "./tools/csvConverterAbs";

const AddWorker = ({ client, user, userInfo }) => {

  const [active, setActive] = useState(false);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [qbEmail, setQBEmail] = useState("");
  const [rfidInput, setRfidInput] = useState();
  const [barcode_idInput, setBarcode_idInput] = useState();
  const [department, setDepartment] = useState(""); 
  const [workerId, setWorkerId] = useState("");
  const [workers, setWorkers] = useState([]);
  const [backupWorkers, setBackupWorkers] = useState([]);
  const [searchFname, setSearchFname] = useState("");
  const [searchLname, setSearchLname] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [searchNum, setSearchNum] = useState("");
  const [isLoading, setLoading]=useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [absence_code, setAbsenceCode] = useState("");
  const [absenceCodeSelected, setSelectedAbsenceCode] = useState({});
  const [reason, setReason] = useState("");
  const [reasonSelected, setSelectedReason] = useState({});
  const [offenseDate, setOffenseDate] = useState("0000-00-00");
  const [offenseTime, setOffenseTime] = useState("00:00");
  const [note, setNote] = useState("");
  const [employee, setEmployee] = useState("");
  const [absenceID, setAbsenceID] = useState("");
  const [excused, setExcused]=useState(false);
  const [absences, setAbsences] = useState([]);
  let today = new Date().toJSON().slice(0,10);
  const [startFilter, setStartFilter] = useState(today + " 00:00:00");
  const [endFilter, setEndFilter] = useState(today + " 00:00:00");
  const absenceCodes = ["Absent","Tardy","Lay Off","Partial Hrs. Worked","Vacation","Holiday"]
  const absenceReasons = [
    {code:1, meaning:"Lack of Work"},
    {code:2, meaning:"Sick (employee)"},
    {code:3, meaning:"Family illness"},
    {code:4, meaning:"Accident (outside plant)"},
    {code:5, meaning:"Plant injury"},
    {code:6, meaning:"Personal"},
    {code:7, meaning:"Discipline"},
    {code:8, meaning:"Leave of Absence"},
    {code:9, meaning:"Transportation"},
    {code:10, meaning:"Unknown"},
    {code:11, meaning:"Death in Family"},
    {code:12, meaning:"Jury Duty"},
    {code:13, meaning:"Birthday"},
    {code:14, meaning:"Military"},
    {code:15, meaning:"Weather"},
    {code:16, meaning:"Medical Appt."},
    {code:19, meaning:"Comp. Time"},
    {code:20, meaning:"Court"},
    {code:21, meaning:"Vacation Day"},
    {code:22, meaning:"Maternity Leave"},
    {code:23, meaning:"Medical Leave/FMLA"},
    {code:24, meaning:"Lay-Off"}];
  const [modelType, setModelType] = useState(1);
  const [selectedModel, setSelectedModel] = useState({ key:1, label:"Employees", value:1 });
  const modelOptions = [
    {num:1, name:"Employees"},
    {num:2, name:"Absences"}];
  const [departments, setDepartments] = useState([]);
  const [departmentFilter, setDepartmentFilter] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [selected, setSelected] = useState(false);
  const [availIDs, setAvailIDs] = useState([]);
  const [selectedAvailID, setSelectedAvailID] = useState({});

  useEffect(() => {
    refreshWorkers();
    apply_absences_filter();
  }, [user]);

  const refreshWorkers = () => {
    setLoading(true);
    client.get("/api/employees")
      .then((res) => {
        setWorkers(res.data);
        setBackupWorkers(res.data);
        let uniqueDepts = [...new Set(res.data.map(w => w.department))];
        uniqueDepts.sort();
        setDepartments(uniqueDepts);
        findAvailableIDs(res.data);
        setLoading(false);
      })
      .catch(function(error) {
        alert("Employees access denied");
        setLoading(false);
      });
  };

  const findAvailableIDs = (data) => {
    let poss = Array.from({length: 9999}, (_, i) => i + 1);
    const results = poss.filter((p) => !data.some((d) => p === d.barcode_id));
    setAvailIDs(results);
  };

  const joinEmpsAndAbs = (demAbs) => {
    const joinArray = demAbs.map((abs, i) => {
      const tempAbs = abs;
      const emp = backupWorkers.find((w) => w.id === abs.employee)
      tempAbs.employee = emp;
      return tempAbs;
    });
    return joinArray;
  };

  const filterWorkersByDepartment = (array) => {
    setWorkers(array.filter(w => w.department === departmentFilter))
  };

  const filterAbsencesByDepartment = (array) => {
    setAbsences(array.filter(a => a.employee.department === departmentFilter))
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let rfid = rfidInput === "" ? null : rfidInput;
    let barcode_id = barcode_idInput === "" ? null : barcode_idInput;
    let username = qbEmail === "" ? null : qbEmail;
    let item = { active, first_name, last_name, username, rfid, barcode_id, department };
    client.post("/api/employees/", item)
    .then(() => {refreshWorkers(); clearSelectedWorker();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

  const onUpdate = (id) => {
    let rfid = rfidInput === "" ? null : rfidInput;
    let barcode_id = barcode_idInput === "" ? null : barcode_idInput;
    let username = qbEmail === "" ? null : qbEmail;
    let item = { active, first_name, last_name, username, rfid, barcode_id, department };
    client.patch(`/api/employees/${id}/`, item)
    .then((res) => {refreshWorkers(); clearSelectedWorker();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

  const onAbsenceSubmit = (e) => {
    e.preventDefault();
    let offense_time = offenseTime;
    let offense_date = offenseDate;
    let created_by = userInfo.user_id;
    let item = { employee, offense_time, offense_date, created_by, absence_code, reason, excused, note };
    client.post("/api/absences/", item)
    .then(() => {clearSelectedAbsence(); apply_absences_filter();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

  const onAbsenceUpdate = (id) => {
    let offense_time = offenseTime;
    let offense_date = offenseDate;
    let updated_by = userInfo.user_id;
    let item = { employee, offense_time, offense_date, updated_by, absence_code, reason, excused, note };
    client.patch(`/api/absences/${id}/`, item)
    .then((res) => {clearSelectedAbsence(); apply_absences_filter();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

  const onAbsenceDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this absence record?")) {
      client.delete(`/api/absences/${id}/`)
      .then((res) => {clearSelectedAbsence(); apply_absences_filter();})
      .catch(function(error) {
        alert(JSON.stringify(error));
      });
    };
  };

  const apply_filter = () => {
    setLoading(true);
    client.get("/api/employees/search_filter/", {
        params: {
            searchFname: searchFname,
            searchLname: searchLname,
            searchActive: searchActive,
            searchNum: searchNum,
          }
    })
      .then((res) => {
        isEmpty(selectedDepartment) ? setWorkers(res.data) : filterWorkersByDepartment(res.data);
        setLoading(false);
      })
      .catch(function(error) {
        alert("employees/search_filter access denied");
        setLoading(false);
      });
  };

  const apply_absences_filter = () => {
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setLoading(true);
      client.get("/api/absences/search_filter/", {
        params: {
          searchFname: searchFname,
          searchLname: searchLname,
          startFilter: startFilter,
          endFilter: endFilter,
        }
      })
      .then((res) => {
        let abs = !isEmpty(backupWorkers) ? joinEmpsAndAbs(res.data) : res.data;
        isEmpty(selectedDepartment) ? setAbsences(abs) : filterAbsencesByDepartment(abs);
        setLoading(false);
      })
      .catch(function(error) {
        alert("Absences/search_filter access denied");
        setLoading(false);
      });
    };
  };

  const selectWorker = (id) => {
    setSelected(true);
    let item = workers.filter((worker) => worker.id === id)[0];
    setActive(item.active);
    setBarcode_idInput(item.barcode_id);
    setDepartment(item.department);
    setFirst_name(item.first_name);
    setLast_name(item.last_name);
    setQBEmail(item.username)
    setRfidInput(item.rfid);
    setWorkerId(item.id);
  };

  const selectAbsence = (absence) => {
    let foundReason = absenceReasons.filter((r) => r.meaning === absence.reason)[0];
    setAbsenceID(absence.id);
    setSelectedOption({key: absence.employee.id, label: absence.employee.first_name+' '+absence.employee.last_name, value: absence.employee.id});
    setEmployee(absence.employee.id);
    setOffenseDate(absence.offense_date);
    setOffenseTime(absence.offense_time);
    setAbsenceCode(absence.absence_code);
    setReason(absence.reason);
    setSelectedReason({key: foundReason.code, label: foundReason.code + '. ' + foundReason.meaning, value: foundReason.meaning});
    setSelectedAbsenceCode({key: absence.absence_code, label: absence.absence_code, value: absence.absence_code});
    setExcused(absence.excused);
    setNote(absence.note);
  };

  const clearSelectedAbsence = () => {
    setAbsenceID("");
    setSelectedOption({});
    setSelectedAbsenceCode({});
    setSelectedReason({});
    setEmployee("");
    setOffenseDate("0000-00-00");
    setOffenseTime("00:00");
    setAbsenceCode("");
    setReason("");
    setExcused(false);
    setNote("");
  };

  const clearSelectedWorker = () => {
    setSelectedAvailID({});
    setSelected(false);
    setActive(false);
    setBarcode_idInput("");
    setDepartment("");
    setFirst_name("");
    setLast_name("");
    setQBEmail("");
    setRfidInput("");
    setWorkerId("");
  };

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  };

  function convertJSONstring(JS_Obj) {
    var obj = eval('(' + JS_Obj + ')');
    var res = [];
    for(var i in obj)
      res.push(obj[i]);
    return res;
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportAbsList = (e) => {
    e.preventDefault();
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setLoading(true);
      client.get("/api/absences/summary/", {
        params: {
          searchDept: departmentFilter, searchFname: searchFname,
          searchLname: searchLname, startFilter: startFilter,
          endFilter: endFilter,
        }
      })
      .then((res) => {
        setLoading(false);
        var obj = convertJSONstring(res.data);
        const csv = absToCSV(absences, startFilter, endFilter, obj);
        const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + ".csv";
        downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
      })
      .catch(function(error) {
        alert("Workperiods/summary access denied");
        setLoading(false);
      });
    };
  };

  const summary = (e) => {
    e.preventDefault();
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setLoading(true);
      client.get("/api/absences/summary/", {
        params: {
          searchDept: departmentFilter,
          searchFname: searchFname,
          searchLname: searchLname,
          startFilter: startFilter,
          endFilter: endFilter,
        }
      })
      .then((res) => {
        setLoading(false);
        var obj = convertJSONstring(res.data);
        const csv = summaryFunc(obj, startFilter, endFilter);
        const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + "summaryAbs.csv";
        downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
      })
      .catch(function(error) {
        alert("Workperiods/summary access denied");
        setLoading(false);
      });
    };
  };

  if (user) {
    return (
        <div className="container-fluid mt-3">
        <div className="row">
          {modelType === 1 && (
            <div className="col-md-3">
            <h3 className="float-left">Employees</h3>
            <Form onSubmit={onSubmit} className="mt-2" id="myForm">
            <Row>
                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>Database #</Form.Label>
                    <Form.Control
                        type="text"
                        value={workerId || ""}
                        disabled={true}
                    />
                </Form.Group>

                <Form.Group as={Col} className="mb-1" controlId="formActive">
                <Form.Label>Active</Form.Label>
                <Form.Check
                    data-bs-theme="dark"
                    type="checkbox"
                    id="default-checkbox"
                    checked={active || false}
                    onChange={(e) => setActive(e.target.checked)}
                />
                </Form.Group>
              </Row>

                <Form.Group className="mb-1" controlId="formBasicFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={first_name || ''}
                    onChange={(e) => setFirst_name(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={last_name || ''}
                    onChange={(e) => setLast_name(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicQBEmail">
                <Form.Label>Email for QuickBooks</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter QB Email"
                    value={qbEmail || ''}
                    onChange={(e) => setQBEmail(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBarcodeId">
                <Form.Label>Barcode ID</Form.Label>
                {!selected && (
                  <Select 
                    label={selectedAvailID.label}
                    value={selectedAvailID}
                    onChange={e => {setSelectedAvailID(e); setBarcode_idInput(e.value);}}
                    options={availIDs.map(o => ({ key:o, label:o, value:o }))}>
                  </Select>
                )}
                {selected && (
                  <Form.Control
                  type="text"
                  placeholder="Enter Barcode ID"
                  value={barcode_idInput || ''}
                  onChange={(e) => {setBarcode_idInput(e.target.value)}}
                  />
                )}
                </Form.Group>

                <Form.Group className="mb-1" controlId="formRfid">
                <Form.Label>RFID</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter RFID"
                    value={rfidInput || ''}
                    onChange={(e) => {setRfidInput(e.target.value)}}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Department"
                    value={department || ''}
                    onChange={(e) => setDepartment(e.target.value)}
                />
                </Form.Group>

                <div className="float-right">
                  {workerId === "" && (
                  <Button size="md" variant="success" type="submit" onClick={onSubmit} className="mx-2">
                    Create
                  </Button>
                  )}
                  {workerId !== "" && (
                  <Button size="md" variant="primary" type="button" onClick={() => {onUpdate(workerId);}} className="mx-2">
                    Update
                  </Button>
                  )}
                  <Button size="md" variant="secondary" type="button" onClick={() => clearSelectedWorker()} className="mx-2">
                    Clear
                  </Button>

                </div>
                <br></br>
                <br></br>
            </Form>
            </div>
          )}
          {modelType === 2 && (
            <div className="col-md-3">
            <h3 className="float-left">Absences</h3>
            <Form onSubmit={onSubmit} className="mt-2" id="myForm">
              <Row>
                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>Database #</Form.Label>
                    <Form.Control
                        type="text"
                        value={absenceID || ""}
                        disabled={true}
                    />
                </Form.Group>
              </Row>

              <Form.Group className="mb-1" controlId="formSelectOption">
              <Form.Label>Employee</Form.Label>
              <Select 
                  label={selectedOption.label}
                  value={selectedOption}
                  onChange={e => {setSelectedOption(e); setEmployee(e.value);}}
                  options={backupWorkers.map(o => ({ key:o.id, label:o.first_name+' '+o.last_name, value:o.id }))}>
              </Select>
              </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicAbsenceCode">
                <Form.Label>Absence Code</Form.Label>
                <Select 
                  label={absenceCodeSelected.label}
                  value={absenceCodeSelected}
                  onChange={e => {setSelectedAbsenceCode(e); setAbsenceCode(e.value);}}
                  options={absenceCodes.map(o => ({ key:o, label:o, value:o }))}>
                </Select>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicReason">
                <Form.Label>Reason</Form.Label>
                <Select 
                  label={reasonSelected.label}
                  value={reasonSelected}
                  onChange={e => {setSelectedReason(e); setReason(e.value);}}
                  options={absenceReasons.map(o => ({ key:o.code, label:o.code + '. ' + o.meaning, value:o.meaning }))}>
                </Select>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formTempInDate">
                    <Form.Label>Date</Form.Label>
                    <Form.Control 
                        type="date" 
                        value={offenseDate || ""}
                        onChange={(e) => {setOffenseDate(e.target.value); }}/>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formTempInTime">
                    <Form.Label>Time (if applicable)</Form.Label>
                    <Form.Control 
                        type="time" 
                        value={offenseTime || ""}
                        onChange={(e) => {setOffenseTime(e.target.value);}}/>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formInProgress">
                  <Form.Label column sm="4">Excused ---&gt;</Form.Label>
                  <Col>
                  <Form.Check className="mt-2"
                      data-bs-theme="dark"
                      type="checkbox"
                      id="default-checkbox"
                      checked={excused || false} // added '|| false' because of warning about changing controlled/uncontrolled component
                      onChange={(e) => setExcused(e.target.checked)}
                  /></Col>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formNote">
                  <Form.Control 
                    as="textarea" 
                    rows={3} 
                    placeholder="Note any additional info"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </Form.Group>

                <div className="float-right">
                  {absenceID === "" && (
                  <Button size="md" variant="success" type="submit" onClick={(e) => onAbsenceSubmit(e)} className="mx-2">
                    Create
                  </Button>
                  )}
                  {absenceID !== "" && (
                  <Button size="md" variant="primary" type="button" onClick={() => {onAbsenceUpdate(absenceID);}} className="mx-2">
                    Update
                  </Button>
                  )}
                  <Button size="md" variant="secondary" type="button" onClick={() => clearSelectedAbsence()} className="mx-2">
                    Clear
                  </Button>

                </div>
                <br></br>
                <br></br>
            </Form>
            </div>
          )}

            <div className="col-md-9 m">
            <div className="row">
            <Form className="mt-0" id="myForm2">
            {modelType === 1 && (
                <Row>
                    <Col className="filter-col">
                    <Form.Group className="mb-0" controlId="formFilter">
                      <h4>Filter:</h4>
                    </Form.Group>

                    <Select 
                      label={selectedModel.name}
                      value={selectedModel}
                      onChange={e => {setSelectedModel(e); setModelType(e.value);}}
                      options={modelOptions.map(o => ({ key:o.num, label:o.name, value:o.num }))}>
                    </Select>
                    </Col>

                    <Col>
                    <Form.Label>Department</Form.Label>
                    <Select 
                      label={selectedDepartment.name}
                      value={selectedDepartment}
                      onChange={e => {setSelectedDepartment(e); setDepartmentFilter(e.value);}}
                      options={departments.map(o => ({ key:o, label:o, value:o }))}>
                    </Select>
                    </Col>
                    
                    <Col>
                    <Form.Group as={Row} className="mb-2 mx-2" controlId="formActive">
                      <Form.Label column sm="4">Active</Form.Label>
                      <Col>
                      <Form.Check className="mt-2"
                          data-bs-theme="dark"
                          type="checkbox"
                          id="default-checkbox"
                          checked={searchActive || false}
                          onChange={(e) => setSearchActive(e.target.checked)}
                      /></Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-2 mx-2" controlId="formSearchNum">
                        <Form.Control 
                            type="text" 
                            placeholder="Barcode/RFID"
                            value={searchNum || ""}
                            onChange={(e) => setSearchNum(e.target.value)}/>
                    </Form.Group>
                    </Col>

                    <Col>
                    <Form.Group className="mb-2" controlId="formFnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="First Name"
                            value={searchFname || ""}
                            onChange={(e) => setSearchFname(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formLnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="Last Name"
                            value={searchLname || ""}
                            onChange={(e) => setSearchLname(e.target.value)}/>
                    </Form.Group>
                    </Col>
                    
                    <Col>
                    <Button className="mb-1 mx-1" size="md" variant="primary"
                      type="button"
                      onClick={() => {apply_filter();}}>
                      Apply Filter
                    </Button>

                    <Button className="mb-1 mx-1" size="md" variant="secondary"
                      type="button"
                      onClick={() => {setWorkers(backupWorkers); setSearchNum(""); 
                      setSearchActive(false); setSearchFname(""); 
                      setSearchLname(""); setSelectedDepartment({}); setDepartmentFilter("");}}>
                      Remove Filter
                    </Button>
                    </Col>
                </Row>
              )}
              {modelType === 2 && (
                <Row>
                  <Col className="filter-col">
                    <Form.Group className="mb-0" controlId="formFilter">
                      <h4>Filter:</h4>
                    </Form.Group>

                    <Select 
                      label={selectedModel.name}
                      value={selectedModel}
                      onChange={e => {setSelectedModel(e); setModelType(e.value);}}
                      options={modelOptions.map(o => ({ key:o.num, label:o.name, value:o.num }))}>
                    </Select>
                    </Col>

                    <Col>
                    <Form.Label>Department</Form.Label>
                    <Select 
                      label={selectedDepartment.name}
                      value={selectedDepartment}
                      onChange={e => {setSelectedDepartment(e); setDepartmentFilter(e.value);}}
                      options={departments.map(o => ({ key:o, label:o, value:o }))}>
                    </Select>
                    </Col>

                    <Col>
                    <Form.Group className="mb-2" controlId="formFnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="First Name"
                            value={searchFname || ""}
                            onChange={(e) => setSearchFname(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formLnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="Last Name"
                            value={searchLname || ""}
                            onChange={(e) => setSearchLname(e.target.value)}/>
                    </Form.Group>
                    </Col>

                    <Form.Group as={Col} className="mb-2" controlId="formStartFilter">
                        <Form.Label>Start Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={startFilter.slice(0,10) || ""}
                            onChange={(e) => setStartFilter(e.target.value+' '+'00:00:00')}/>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formEndFilter">
                        <Form.Label>End Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={endFilter.slice(0,10) || ""}
                            onChange={(e) => setEndFilter(e.target.value+' '+'23:59:59')}/>
                    </Form.Group>
                    
                    <Col>
                    <Button
                    className="mb-1"
                    size="md"
                    variant="primary"
                    type="button"
                    onClick={() => apply_absences_filter()}
                    >
                    Apply Filter
                    </Button>

                    <Button
                    className="mb-1"
                    size="md"
                    variant="secondary"
                    type="button"
                    onClick={() => {setAbsences([]); setStartFilter("0000-00-00 00:00:00"); 
                    setEndFilter("0000-00-00 00:00:00"); setSearchFname(""); 
                    setSearchLname(""); setSelectedDepartment({}); setDepartmentFilter("");}}
                    >
                    Remove Filter
                    </Button>
                    </Col>

                    <Col>
                      <Button className="mb-1 mx-1" size="md" variant="warning" type="button"
                        onClick={(e) => {summary(e);}}>
                        Summary
                      </Button>

                      <Button className="mb-1 mx-1" size="md" variant="warning" type="button"
                        onClick={(e) => {exportAbsList(e);}}>
                        Detailed List
                      </Button>
                    </Col>
                </Row>
              )}
            </Form>
            </div>
            
            {modelType === 1 && (
            <div className="tableContainer">
            <table className="table">
                <thead background_color="gray">
                <tr>
                    <th scope="col">Employee</th>
                    <th scope="col">Email</th>
                    <th scope="col">Active</th>
                    <th scope="col">Department</th>
                    <th scope="col">Barcode</th>
                    <th scope="col">RFID</th>
                    <th scope="col">N = {workers.length}</th>
                </tr>
                </thead>
                <tbody>
                {!isLoading && workers.map((worker, index) => {
                    return (
                    <tr key={index}>
                        <th>{worker.first_name} {worker.last_name}</th>
                        <td>{(worker.username != null) && (worker.username.length > 18 ? worker.username.slice(0,18) + "..." : worker.username)}
                        </td>
                        <td>
                            <input type='checkbox' name='active' disabled={true} checked={worker.active}></input>
                        </td>
                        <td>{worker.department}</td>
                        <td>{worker.barcode_id}</td>
                        <td>{worker.rfid}</td>
                        <td>
                            <Button>
                                <FontAwesomeIcon icon={faSquarePen} 
                                onClick={() => selectWorker(worker.id)}/>
                            </Button>&nbsp;
                            {/* <Button variant="danger">
                                <FontAwesomeIcon icon={faTrash}  
                                onClick={() => onDelete(worker.id)}/>
                            </Button> */}
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            {isLoading && (
              <div id="load" className="center">
              <h3>loading...</h3>
            </div>
            )}
            </div>
            )}

            {modelType === 2 && (
            <div className="tableContainer">
            <table className="table">
                <thead background_color="gray">
                <tr>
                    <th scope="col">Employee</th>
                    <th scope="col">Date</th>
                    <th scope="col">Code</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Note</th>
                    <th scope="col">Excused</th>
                    <th scope="col">N = {absences.length}</th>
                </tr>
                </thead>
                <tbody>
                {!isLoading && absences.map((absence, index) => {
                    return (
                    <tr key={index}>
                        <th>{absence.employee.first_name} {absence.employee.last_name}</th>
                        <td>{absence.offense_date.slice(5,7)}/{absence.offense_date.slice(8,10)}</td>
                        <td>{absence.absence_code}</td>
                        <td>{absence.reason}</td>
                        <td>{absence.note}</td>
                        <td>
                            <input type='checkbox' name='excused' disabled={true} checked={absence.excused}></input>
                        </td>
                        <td>
                            <Button>
                                <FontAwesomeIcon icon={faSquarePen} 
                                onClick={() => selectAbsence(absence)}/>
                            </Button>&nbsp;
                            <Button variant="danger">
                                <FontAwesomeIcon icon={faTrash}  
                                onClick={() => onAbsenceDelete(absence.id)}/>
                            </Button>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            {isLoading && (
              <div id="load" className="center">
              <h3>loading...</h3>
            </div>
            )}
            </div>
            )}

            </div>
        </div>
        </div>
    );
  }
  else {
    <div>
        Access denied.
    </div>
  };
};

export default AddWorker;