
export function summaryFunc (data, startFilter, endFilter) {

    const fileName = startFilter.slice(0,10) + "--" + endFilter.slice(0,10);
    const csvRows = [fileName];
    const headers = ["employee__id","employee__department","employee__first_name","employee__last_name",
                    "absent", "partial", "tardy","excused_count", "unexcused_count"]
    csvRows.push(headers.join(','));

    let index = 0;
    for (const row of data) {
        const values = headers.map(header => {
            const val = row[header];
            return `"${val}"`;
        });
        csvRows.push(values.join(','));
        index++;
    }
   
    return csvRows.join('\n');
}
