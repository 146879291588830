import moment, { duration } from 'moment';

export function generateReportArray (data) {

    const clone = JSON.parse(JSON.stringify(data));
    const reportArray = [];

    for (const row of data) {
        let oldin = moment(row["punch_in"]);
        let oldout = moment(row["punch_out"]);
        let newin = moment(oldin);
        let newout = moment(oldout);
        let adj = {
            employee: row["employee"]["id"],
            employee_name: row["employee"]["first_name"] + " " + row["employee"]["last_name"],
            work_period: row["id"],
            old_in: moment(oldin).format("YYYY-MM-DDTHH:mm:ssZ"),
            old_out: moment(oldout).format("YYYY-MM-DDTHH:mm:ssZ"),
            new_in: "",
            new_out: "",
            old_duration: row["duration"],
            note: "",
            in_applied: false,
            out_applied: false,
            delete: false
        };
        
        if (row["in_progress"]) {
            continue;
        }

        let empRows = data.filter((r) => r.employee.id === row["employee"]["id"]);
        let empRowsOther = empRows.filter((r) => r.id != row["id"]);
        for (const oRow of empRowsOther) {
            if (oRow["punch_in"] === row["punch_out"] || oRow["punch_out"] === row["punch_in"]) {
                adj.note += "Overlap?"
            }
        }

        if (adj.old_duration <= 0.15) {
            adj.note += "delete?"
            // adj.delete = true;
            reportArray.push(adj);
            continue;
        }

        if (oldin.hour() >= 7 && oldin.hour !== 12 && oldin.minute()%15===0) {
            // pass
        } 
        // else if (oldin.hour() >= 7 && oldin.hour !== 12 && oldin.minute()%15===0 && oldin.second()!==0) {
        //     newin.second(0);
        //     adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "0sec/"
        // } 
        else if (oldin.hour() < 7) {
            newin.hour(7);
            newin.minute(0);
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "Pre7AM--"
        } else if (oldin.hour() === 7 && oldin.minute() < 6) {
            newin.hour(7);
            newin.minute(0);
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "7amGrace--"
        } else if (oldin.hour() >= 7 && oldin.hour() !== 12) {
            if (Math.floor(oldin.minute()/15) === 0) {
                newin.minute(15);
            } else if (Math.floor(oldin.minute()/15) === 1) {
                oldin.minute()%15 === 0 ? newin.minute(15) : newin.minute(30);
            } else if (Math.floor(oldin.minute()/15) === 2) {
                oldin.minute()%15 === 0 ? newin.minute(30) : newin.minute(45);
            } else if (Math.floor(oldin.minute()/15) === 3) {
                oldin.minute()%15 === 0 ? newin.minute(45) : newin.hour(oldin.hour() + 1).minute(0);
            }
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "LatePenalty--"
        } else if (oldin.hour() === 12 && oldin.minute() === 30) {
            // pass
        } 
        // else if (oldin.hour() === 12 && oldin.minute() === 30 && oldin.second() !== 0) {
        //     newin.second(0);
        //     adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "0sec/"
        // }
        else if (oldin.hour() === 12 && oldin.minute() > 30 && oldin.minute() < 36) {
            newin.minute(30);
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "LunchGrace--"
        } else if (oldin.hour() === 12 && oldin.minute() <= 30) {
            newin.minute(30);
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "EarlyLunchReturn--"
        } else if (oldin.hour() === 12 && oldin.minute() > 35) {
            if (oldin.minute() <= 45) {
                newin.minute(45);
            } else if (oldin.minute() > 45) {
                newin.hour(oldin.hour() + 1).minute(0);
            }
            newin.second(0);
            adj.new_in = moment(newin).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "LateLunchReturn--"
        } // end of IN rules

        if (oldout.hour() >= 7 && oldout.hour !== 12 && oldout.minute()%15===0 && oldout.second()===0) {
            // pass
        } 
        // else if (oldout.hour() >= 7 && oldout.hour !== 12 && oldout.minute()%15===0 && oldout.second() !== 0) {
        //     newout.second(0);
        //     adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "0sec/"
        // } else if (oldout.hour() === 12 && oldout.minute() === 0 && oldout.second() === 0) {
        //     // pass
        // } else if (oldout.hour() === 12 && oldout.minute() === 30 && oldout.second() !== 0) {
        //     newout.second(0);
        //     adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "0sec/"
        // } 
        else if (oldout.hour() === 12 && adj.old_duration > 0.25 && oldout.minute() >= 30) {
            adj.note += "FailedToClockOutCorrectlyForLunch?--"
        }
        // else if (oldout.hour() === 12 && oldout.minute() === 0 && oldout.second()!==0) {
        //     newout.second(0);
        //     adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "0sec/"
        // } 
        else if (oldout.hour() === 12 && oldout.minute() < 30 && oldout.minute() > 0) {
            newout.minute(0);
            newout.second(0);
            adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
            adj.note += "LateLunchExit--"
        } 
        // else if (oldout.minute()%15 < 8) {
        //     newout.minute(Math.floor(oldout.minute()/15)*15);
        //     newout.second(0);
        //     adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "OutRoundDown/"
        // } else if (oldout.minute()%15 >= 8) {
        //     Math.ceil(oldout.minute()/15) === 4 ? newout.hour(oldout.hour()+1).minute(0) : newout.minute(Math.ceil(oldout.minute()/15)*15);
        //     newout.second(0);
        //     adj.new_out = moment(newout).format("YYYY-MM-DDTHH:mm:ssZ");
        //     adj.note += "OutRoundUp/"
        // } // end of OUT rules
        if (row["duration"] > 5 && row["duration"] < 7.5) {
            adj.note += "ExtraTime--"
        }
        if (row["duration"] > 7.5) {
            adj.note += "FailedToClockOutForLunch?--"
        }

        if (adj.work_period !== "") {
            reportArray.push(adj);
        };
    }
    return reportArray;
}



