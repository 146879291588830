import { Outlet } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

const Layout = ({user, userInfo, setCurrentUser, client}) => {

  function submitLogout(e) {
    e.preventDefault();
    client.post(
      "/user_api/logout",
      {withCredentials: true}
    ).then(function(res) {
      setCurrentUser(current => false);
    })
    .catch(function(error) {
      console.log("Layout");
    });
  };

  if (user) {
    return (
      <>
      <div>
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>Addison</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/log">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/timeclock">
                  <Nav.Link>TimeClock</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/workforce">
                  <Nav.Link>Workforce</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/workperiods">
                  <Nav.Link>TimePeriods</Nav.Link>
                </LinkContainer>
                {userInfo.user_level === 1 && (
                  <LinkContainer to="/administration">
                  <Nav.Link>Admin</Nav.Link>
                </LinkContainer>
                )}
              </Nav>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Signed in as: {userInfo.username}&nbsp;&nbsp;
            </Navbar.Text>
              <Navbar.Text>
                <form onSubmit={e => submitLogout(e)}>
                  <Button type="submit" variant="light">Log out</Button>
                </form>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      

      <Outlet />
      </>
    );
  }
  else {
    return (
      <>
      <div>
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>Addison</Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/log">
                  <Nav.Link>Login</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
            
          </Container>
        </Navbar>
      </div>

      

      <Outlet />
      </>
    );
  }
}

export default Layout;