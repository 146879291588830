import { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select';

const AddUser = ({ client, user, userInfo }) => {

  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [userLevel, setUserLevel] = useState("");
  const [password, setPassword] = useState("");
  const [userID, setUserID] = useState("");
  const levels = [
    {level:0, meaning:"No access (instead of deleting)"},
    {level:1, meaning:"Admin (full access)"},
    {level:2, meaning:"Payroll (full access except Admin)"},
    {level:3, meaning:"HR (TimeClock + Workforce)"},
    {level:4, meaning:"Supervisors (TimeClock + clocked in/out tables)"},
    {level:9, meaning:"TimeClocks (displays activity at this clock only)"}];
  const [levelSelected, setSelectedLevel] = useState({});


  useEffect(() => {
    refreshUsers();
  }, [user]);

  const refreshUsers = () => {
    setLoading(true);
    client.get("/user_api/listUsers")
      .then((res) => {
        let data = res.data;
        data.sort(function(a,b){
            let x = a.user_level;
            let y = b.user_level;
            return x-y;
          });
        setUsers(data);
        setLoading(false);
      })
      .catch(function(error) {
        alert("Employees access denied");
        setLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (username === "" || password === "" || email === "" || userLevel === "") {
        window.alert("Complete every field");
        return;
    };
    client.post(
        "/user_api/register",
        {
          email: email,
          username: username,
          password: password,
          user_level: userLevel
        }
    )
    .then(() => {refreshUsers(); clearSelectedUser();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

  const onUpdate = (id) => {
    let user_level = userLevel;
    let item = { username, email, password, user_level };
    client.patch(`/user_api/updateUser/${id}/`, item)
    .then((res) => {refreshUsers(); clearSelectedUser();})
    .catch(function(error) {
      alert(JSON.stringify(error.response.data));
    });
  };

//   const onDelete = (id) => {
//     if (window.confirm("Are you sure you want to delete this user?")) {
//         client.delete(`/user_api/deleteUser/${id}/`)
//         .then((res) => {refreshUsers(); clearSelectedUser();})
//         .catch(function(error) {
//             alert(JSON.stringify(error.response.data));
//         });
//     };
//   };

  const selectUser = (id) => {
    setSelected(true);
    let item = users.filter((filterUser) => filterUser.user_id === id)[0];
    let tempLevel = levels.filter((r) => r.level === item.user_level)[0];
    setUsername(item.username);
    setEmail(item.email);
    setUserLevel(item.user_level);
    setUserID(item.user_id);
    setSelectedLevel({key:tempLevel.level, label:tempLevel.level + '. ' + tempLevel.meaning, value:tempLevel.level});
  };

  const clearSelectedUser = () => {
    setSelected(false);
    setUsername("");
    setPassword("");
    setEmail("");
    setUserLevel("");
    setUserID("");
    setSelectedLevel({});
  };


  if (user) {
    return (
        <div className="container-fluid mt-3">
        <div className="row">
            <div className="col-md-3">
            <h3 className="float-left">Users</h3>
            <Form onSubmit={onSubmit} className="mt-2" id="myForm">
            <Row>
                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>Database #</Form.Label>
                    <Form.Control
                        type="text"
                        value={userID || ""}
                        disabled={true}
                    />
                </Form.Group>
            </Row>

                <Form.Group className="mb-1" controlId="formBasicLastName">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Email for Login"
                        value={email || ''}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={selected}
                    />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicFirstName">
                <Form.Label>Username</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value)}
                />
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBasicReason">
                <Form.Label>User Level</Form.Label>
                <Select 
                  label={levelSelected.label}
                  value={levelSelected}
                  onChange={e => {setSelectedLevel(e); setUserLevel(e.value);}}
                  options={levels.map(o => ({ key:o.level, label:o.level + '. ' + o.meaning, value:o.level }))}>
                </Select>
                </Form.Group>

                {!selected && (
                    <Form.Group className="mb-1" controlId="formRfid">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Password"
                        value={password || ''}
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                    </Form.Group>
                )}

                {selected && (
                    <Form.Group className="mb-1" controlId="formRfid">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter New Password or Leave Empty"
                        value={password || ''}
                        onChange={(e) => {setPassword(e.target.value)}}
                    />
                    </Form.Group>
                )}

                <div className="float-right">
                  {userID === "" && (
                  <Button size="md" variant="success" type="submit" className="mx-2"
                    onClick={(e) => {
                        onSubmit(e);
                    }}>
                    Create
                  </Button>
                  )}
                  {userID !== "" && (
                  <Button size="md" variant="primary" type="button" className="mx-2"
                    onClick={() => {
                        onUpdate(userID);
                    }}>
                    Update
                  </Button>
                  )}
                  <Button size="md" variant="secondary" type="button" className="mx-2"
                  onClick={() => {
                    clearSelectedUser();
                  }}>
                    Clear
                  </Button>

                </div>
                <br></br>
                <br></br>
            </Form>
            </div>

            <div className="col-md-9 m">
            <div className="tableContainer">
            <table className="table">
                <thead background_color="gray">
                <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">User Level</th>
                    <th scope="col">N = {users.length}</th>
                </tr>
                </thead>
                <tbody>
                {!isLoading && users.map((mapUser, index) => {
                    return (
                    <tr key={index}>
                        <th>{mapUser.username}</th>
                        <td>{mapUser.email}</td>
                        <td>{mapUser.user_level}</td>
                        <td>
                            <Button>
                                <FontAwesomeIcon icon={faSquarePen} 
                                onClick={() => selectUser(mapUser.user_id)}/>
                            </Button>&nbsp;
                            {/* <Button variant="danger">
                                <FontAwesomeIcon icon={faTrash}  
                                onClick={() => onDelete(mapUser.user_id)}/>
                            </Button> */}
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            {isLoading && (
              <div id="load" className="center">
              <h3>loading...</h3>
            </div>
            )}
            </div>

            </div>
        </div>
        </div>
    );
  }
  else {
    <div>
        Access denied.
    </div>
  };
};

export default AddUser;