
export function absToCSV (data, startFilter, endFilter, summary) {

    const fileName = startFilter.slice(0,10) + "--" + endFilter.slice(0,10);
    const csvRows = [fileName];
    const headers = ["id","department","name",
                    "offense_date", "offense_time", "absence_code","reason", "excused",'note']
    csvRows.push(headers.join(','));

    const headersSumm = ["employee__id", "absent", "partial", "tardy","excused_count", "unexcused_count"]

    let index = 0;
    for (const row of data) {
        if ((index > 0 || index === data.length - 1) && data.length != 1){
            if (row["employee"]["id"] !== data[index-1]["employee"]["id"]){
                let match = summary.filter((res) => res.employee__id === data[index-1]["employee"]["id"])[0];
                // csvRows.push(headersSumm.join(','));
                const values = headersSumm.map(header => {
                    if (header==="employee__id"){
                        const val = "Summary ("+match["employee__first_name"]+" "+match["employee__last_name"].slice(0,1) +".):" 
                        return `"${val}"`;
                    } else {
                        const val = header + ": " + match[header];
                        return `"${val}"`;
                    };
                });
                csvRows.push(values.join(','));
            };
        };

        const values = headers.map(header => {
            if (header === "id") {
                const val = row["employee"]["id"];
                return `"${val}"`;
            } else if (header === "department") {
                const val = row["employee"]["department"];
                return `"${val}"`;
            } else if (header === "name") {
                const val = row["employee"]["first_name"] + " " + row["employee"]["last_name"];
                return `"${val}"`;
            } else {
                const val = row[header];
                return `"${val}"`;
            };
        });
        csvRows.push(values.join(','));

        if (index === data.length - 1){
            let match = summary.filter((res) => res.employee__id === data[index]["employee"]["id"])[0];
            // csvRows.push(headersSumm.join(','));
            const values = headersSumm.map(header => {
                if (header==="employee__id"){
                    const val = "Summary ("+match["employee__first_name"]+" "+match["employee__last_name"].slice(0,1) +".):" 
                    return `"${val}"`;
                } else {
                    const val = header + ": " + match[header];
                    return `"${val}"`;
                };
            });
            csvRows.push(values.join(','));
        };

        index++;
    }
   
    return csvRows.join('\n');
}
