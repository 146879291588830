import moment from 'moment';

export function csvTimesheet (data) {

    const csvRows = [];

    const headers = ["name", "date", "punch_in","punch_out","duration", "in_progress","department"];

    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            if (header === "name") {
                const val = row["employee"]["first_name"] + " " + row["employee"]["last_name"];
                return `"${val}"`;
            } else if (header === "date") {
                let punch = row["punch_in"].slice(5,7)+ "/" + row["punch_in"].slice(8,10)+"/"+row["punch_in"].slice(0,4);
                const val = punch;
                return `"${val}"`;
            } else if (header === "punch_in") {
                let punch = row["punch_in"].slice(11,16);
                const val = punch;
                return `"${val}"`;
            } else if (header === "punch_out") {
                let punch = row["punch_out"].slice(11,16);
                const val = punch;
                return `"${val}"`;
            } else if (header === "duration") {
                const val = row["duration"];
                return `"${val}"`;
            } else if (header === "department") {
                const val = row["employee"]["department"];
                return `"${val}"`;
            } else if (header === "in_progress") {
                const val = row["in_progress"];
                return `"${val}"`;
            }
        });

        csvRows.push(values.join(','));
    }
    
    return csvRows.join('\n');
}



