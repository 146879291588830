import moment from 'moment';

export function objectToCSV (data) {

    const csvRows = [];

    const headers = ["username","in_time","out_time","tz","jobcode","notes","custom field name","custom field value"]

    csvRows.push(headers.join(','));

    for (const row of data) {
        const values = headers.map(header => {
            if (header === "username") {
                const val = row["employee"]["username"];
                return `"${val}"`;
            } else if (header === "in_time") {
                let punch = moment(row["punch_in"]).format('MM/DD/YYYY hh:mm A') //QB don't like seconds....
                const val = punch;
                return `"${val}"`;
            } else if (header === "out_time") {
                let punch = moment(row["punch_out"]).format('MM/DD/YYYY hh:mm A') //QB don't like seconds....
                const val = punch;
                return `"${val}"`;
            } else if (header === "tz") {
                const val = "-5";
                return `"${val}"`;
            } else if (header === "jobcode") {
                const val = "NEIL M FOOTWEAR";
                return `"${val}"`;
            } else {
                const val = "";
                return `"${val}"`;
            }
        });

        csvRows.push(values.join(','));
    }
    
    return csvRows.join('\n');
}



