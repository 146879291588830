import { useState, useEffect } from "react";
import { Button, Form, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePen, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { objectToCSV } from './tools/csvConverterQB';
import { summaryFunc } from "./tools/summaryWP";
import { generateReportArray } from "./tools/adjustmentReport";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { clearFilterTT, summaryTT, adjustmentTT, quickbooksTT, timesheetTT, fishyTT, absentTT } from './otherFeatures/toolTips';
import moment from "moment";
import { csvTimesheet } from "./tools/csvTimesheet";
import { generateFishyArray } from "./tools/fishyReport";
import { csvAbsences } from "./tools/csvAbsencesWP";

const AddWorkPeriod = ({ client, user, userInfo }) => {

  const [punch_in, setPunchIn] = useState("");
  const [employee, setEmployeeId] = useState("");
  const [in_progress, setInProgress] = useState(false);
  const [punch_out, setPunchOut] = useState("");
  const [workers, setWorkers] = useState([]);
  const [workPeriods, setWorkPeriods] = useState([]);
  const [inProgWorkPeriods, setInProgWorkPeriods] = useState([]);
  const [selectedWP, setSelectedWP] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [inReport, setInReport] = useState(false);
  const [reportedAdjs, setReportedAdjs] = useState([]);
  const [updateNote, setUpdateNote] = useState("");
  const [adjustments, setAdjustments] = useState([]);
  const [specialPeriods, setSpecialPeriods] = useState([]);
  const [selectedSP, setSelectedSP] = useState({});
  const [start_timeSP, setStartTimeSP] = useState("");
  const [end_timeSP, setEndTimeSP] = useState("");
  const [noteSP, setNoteSP] = useState("");
  const [special_type, setSpecialType] = useState("");
  const [makeSP, setMakeSP] = useState(false);
  let today = new Date().toJSON().slice(0,10);
  const [tempInDate, setTempInDate] = useState("0000-00-00");
  const [tempInTime, setTempInTime] = useState("00:00");
  const [tempOutDate, setTempOutDate] = useState("0000-00-00");
  const [tempOutTime, setTempOutTime] = useState("00:00");
  const [startFilter, setStartFilter] = useState(today + " 00:00:00");
  const [endFilter, setEndFilter] = useState(today + " 00:00:00");
  const [searchFname, setSearchFname] = useState("");
  const [searchLname, setSearchLname] = useState("");
  const [modelType, setModelType] = useState(1);
  const [selectedModel, setSelectedModel] = useState({ key:1, label:"Work Periods", value:1 });
  const modelOptions = [
    {num:1, name:"Work Periods"},
    {num:2, name:"Adjustments"},
    {num:3, name:"Special Periods"}
  ];
  const [selectedAdj, setSelectedAdj] = useState({
    index: "", employee: "", employee_name: "", work_period: "", old_in: "",
    old_out: "", new_in: "", new_out: "", old_duration: "", note: "", 
    split: false, in_applied: false, out_applied: false, delete: false
  });
  const [tempNewInTime, setTempNewInTime] = useState("");
  const [tempNewOutTime, setTempNewOutTime] = useState("");

  useEffect(() => {
    refreshWorkers();
  }, [user]);

  useEffect(() => {
    onJob(false); 
    apply_filter();
  }, [workers]);

  const joinEmpsAndWPs = (demPeriods) => {
    const joinArray = demPeriods.map((wp, i) => {
      const tempwp = wp;
      const emp = workers.find((w) => w.id === wp.employee)
      tempwp.employee = emp;
      return tempwp;
    });
    return joinArray;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSelectedAdj(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

  const updateReportedAdjs = () => {
    const nextReportedAdjs = reportedAdjs.map((adj, i) => {
      if (i === selectedAdj.index) {
        return selectedAdj;
      } else {
        return adj;
      }
    });
    setReportedAdjs(nextReportedAdjs);
    clearSelectedAdj();
  };

  function convertJSONstring(JS_Obj) {
    var obj = eval('(' + JS_Obj + ')');
    var res = [];
    for(var i in obj)
      res.push(obj[i]);
    return res;
  };

  const refreshWorkers = () => {
    client.get("/api/employeesslim")
      .then((res) => {
        setWorkers(res.data);
      })
      .catch(function(error) {
        alert("Employees access denied");
      });
  };

  const onJob = (remove) => {
    setLoading(true);
    setInReport(false);
    client.get("/api/workperiodsslim/on_job")
      .then((res) => {
        let onJob = !isEmpty(workers) ? joinEmpsAndWPs(res.data) : res.data;
        setInProgWorkPeriods(onJob);
        if (remove) {
          setWorkPeriods(onJob);
        };
        setLoading(false);
      })
      .catch(function(error) {
        alert("Workperiods/on_job access denied");
        setLoading(false);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let created_by = userInfo.user_id;
    let item = { employee, punch_in, in_progress, punch_out, created_by };
    let inProgCheck = inProgWorkPeriods.find((inProgWorkPeriod) => inProgWorkPeriod.employee.id === employee);
    if (inProgCheck && in_progress) {
      alert("This employee has a WorkPeriod in progress. Cannont create another WorkPeriod with satus 'In Progress' until the former is complete.");
      setLoading(false);
    } else {
      client.post("/api/workperiods/", item)
      .then((res) => {
        let filter = (startFilter !== "0000-00-00 00:00:00" && endFilter !== "0000-00-00 00:00:00");
        if (filter) {apply_filter(); onJob(false)}
        else {onJob(true)};
        clearSelectedPeriod();
      })
      .catch(function(error) {
        alert("Workperiods/post access denied");
        setLoading(false);
      });
    };
  };

  const onUpdate = (id) => {
    if (updateNote === "") {
      alert("Please enter reason for updating.");
      return;
    }
    setLoading(true);
    let updated_by = userInfo.user_id;
    let item = { employee, punch_in, in_progress, punch_out, updated_by };
    let inProgEmp = inProgWorkPeriods.find((inProgWorkPeriod) => inProgWorkPeriod.employee.id === employee);
    if (inProgEmp && in_progress) {
      alert("This employee has a WorkPeriod in progress. Cannont change another WorkPeriod to satus 'In Progress' until the former is complete.");
      setLoading(false);
      return;
    };
    client.patch(`/api/workperiods/${id}/`, item)
    .then((res) => {
      createWPA();
      let filter = (startFilter !== "0000-00-00 00:00:00" && endFilter !== "0000-00-00 00:00:00");
      if (filter) {apply_filter(); onJob(false)}
      else {onJob(true)};
    })
    .catch(function(error) {
      alert("Workperiods/patch access denied");
      clearSelectedPeriod();
      setLoading(false);
    });
  };

  const onDelete = (id, employeeId) => {
    if (window.confirm("Are you sure you want to delete this work period?")) {
      setLoading(true);
      client.delete(`/api/workperiods/${id}/`)
      .then((res) => {
        let filter = (startFilter !== "0000-00-00 00:00:00" && endFilter !== "0000-00-00 00:00:00");
        if (filter) {apply_filter(); onJob(false)}
        else {onJob(true)};
        clearSelectedPeriod();
      })
      .catch(function(error) {
        alert("Workperiods/delete access denied");
        setLoading(false);
      });
    };
  };

  const createWPA = () => {
    let work_period = selectedWP.id;
    let updated_by = userInfo.user_id;
    let old_in = selectedWP.punch_in;
    let old_out = selectedWP.punch_out;
    let new_in = punch_in;
    let new_out = punch_out;
    let old_inProgress = selectedWP.in_progress;
    let new_inProgress = in_progress;
    let note = updateNote;
    let auto_generated = false;
    let item = { work_period, employee, updated_by, old_in, old_out, new_in, new_out, old_inProgress, new_inProgress, note, auto_generated };
    client.post("/api/adjustments/", item)
      .then((res) => {
        clearSelectedPeriod();
      })
      .catch(function(error) {
        alert("Adjustment/post access denied");
        clearSelectedPeriod();
        setLoading(false);
      });
  };

  const updateWPA = () => {
    setLoading(true);
    let id = selectedAdj.id;
    let updated_by = userInfo.user_id;
    let note = selectedAdj.note;
    let auto_generated = false;
    let item = { updated_by, note, auto_generated };
    client.patch(`/api/adjustments/${id}/`, item)
      .then((res) => {
        clearSelectedAdj();
        apply_filter();
        setLoading(false);
      })
      .catch(function(error) {
        alert("Adjustment/patch access denied");
        setLoading(false);
      });
  };

  const createSP = () => {
    console.log("hi");
    // setLoading(true);
    // let work_period = selectedWP.id;
    // let updated_by = userInfo.user_id;
    // let old_in = selectedWP.punch_in;
    // let old_out = selectedWP.punch_out;
    // let new_in = punch_in;
    // let new_out = punch_out;
    // let old_inProgress = selectedWP.in_progress;
    // let new_inProgress = in_progress;
    // let note = updateNote;
    // let auto_generated = false;
    // let item = { work_period, employee, updated_by, old_in, old_out, new_in, new_out, old_inProgress, new_inProgress, note, auto_generated };
    // client.post("/api/specialperiods/", item)
    //   .then((res) => {
    //     clearSelectedSP();
    //   })
    //   .catch(function(error) {
    //     alert("specialperiods/post access denied");
    //     setLoading(false);
    //   });
  };

  const updateSP = () => {
    setLoading(true);
    let id = selectedAdj.id;
    let updated_by = userInfo.user_id;
    let note = selectedAdj.note;
    let auto_generated = false;
    let item = { updated_by, note, auto_generated };
    client.patch(`/api/specialperiods/${id}/`, item)
      .then((res) => {
        clearSelectedAdj();
        apply_filter();
        setLoading(false);
      })
      .catch(function(error) {
        alert("specialperiods/patch access denied");
        setLoading(false);
      });
  };

  const apply_filter = () => {
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setInReport(false);
      setLoading(true);
      clearSelectedAdj();
      if (modelType === 1) {
        client.get("/api/workperiodsslim/search_filter/", {
          params: {
            searchFname: searchFname, searchLname: searchLname,
            startFilter: startFilter, endFilter: endFilter,
          }
        })
        .then((res) => {
          let filtered = !isEmpty(workers) ? joinEmpsAndWPs(res.data) : res.data;
          setWorkPeriods(filtered);
          setLoading(false);
        })
        .catch(function(error) {
          alert("Workperiods/search_filter access denied");
          setLoading(false);
        });
      } else if (modelType === 2) {
        client.get("/api/adjustments/search_filter/", {
          params: {
            searchFname: searchFname, searchLname: searchLname,
            startFilter: startFilter, endFilter: endFilter,
          }
        })
        .then((res) => {
          let filtered = !isEmpty(workers) ? joinEmpsAndWPs(res.data) : res.data;
          setAdjustments(filtered);
          setLoading(false);
        })
        .catch(function(error) {
          alert("Adjustments/search_filter access denied");
          setLoading(false);
        });
      };
    };
  };

  const summary = (e) => {
    e.preventDefault();
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setLoading(true);
      client.get("/api/workperiods/summary/", {
        params: {
          searchFname: searchFname,
          searchLname: searchLname,
          startFilter: startFilter,
          endFilter: endFilter,
        }
      })
      .then((res) => {
        setLoading(false);
        var obj = convertJSONstring(res.data);
        const csv = summaryFunc(obj);
        const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + "summary.csv";
        downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
      })
      .catch(function(error) {
        alert("Workperiods/summary access denied");
        setLoading(false);
      });
    };
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCSVQB = (e) => {
    e.preventDefault();
    const csv = objectToCSV(workPeriods);
    const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + "___QB.csv";
    downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
  };

  const exportTimesheet = (e) => {
    e.preventDefault();
    let filtered = [];
    if (startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00") {
      window.alert("Both start and end date must be chosen.")
    } else {
      setInReport(false);
      setLoading(true);
      clearSelectedAdj();
      if (modelType === 1) {
        client.get("/api/workperiodsslim/export_timesheet/", {
          params: {
            searchFname: searchFname, searchLname: searchLname,
            startFilter: startFilter, endFilter: endFilter,
          }
        })
        .then((res) => {
          filtered = !isEmpty(workers) ? joinEmpsAndWPs(res.data) : res.data;
          // setWorkPeriods(filtered);
          setLoading(false);
          const csv = csvTimesheet(filtered);
          const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + "___Timesheet.csv";
          downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
        })
        .catch(function(error) {
          alert("Workperiods/search_filter access denied");
          setLoading(false);
        });
      };
    };
  };

  const exportAbsencesReport = (e) => {
    e.preventDefault();
    setLoading(true);
    const csv = csvAbsences(workPeriods, workers);
    const fileName = startFilter.slice(0,10) + "___" + endFilter.slice(0,10) + "___Absences.csv";
    downloadFile({ data: csv, fileName: fileName, fileType: 'text/csv' });
    setLoading(false);
  };

  const selectWorkPeriod = (id) => {
    let item = workPeriods.filter((workPeriod) => workPeriod.id === id)[0];
    setSelectedWP(item);
    setPunchIn(item.punch_in);
    setTempInDate(item.punch_in.slice(0,10));
    setTempInTime(item.punch_in.slice(11,16));
    setPunchOut(item.punch_out);
    setTempOutDate(item.punch_out.slice(0,10));
    setTempOutTime(item.punch_out.slice(11,16));
    setInProgress(item.in_progress);
    setEmployeeId(item.employee.id);
    setSelectedOption({key: item.employee.id, label: item.employee.first_name+' '+item.employee.last_name, value: item.employee.id});
    setStartTimeSP(item.punch_in);
    setEndTimeSP(item.punch_out);
  };

  const selectAdjRec = (i) => {
    setSelectedAdj(reportedAdjs[i]);
    setSelectedAdj(prevState => ({
      ...prevState,
      index: i
    }));
    setTempNewInTime(reportedAdjs[i].new_in.slice(11,16));
    setTempNewOutTime(reportedAdjs[i].new_out.slice(11,16));
  };

  const selectSP = (id) => {
    let item = specialPeriods.filter((specialPeriod) => specialPeriod.id === id)[0];
    setSelectedSP(item);
    setSelectedWP({id: item.work_period});
    setStartTimeSP(item.start_time);
    setTempInDate(item.start_time.slice(0,10));
    setTempInTime(item.start_time.slice(11,16));
    setEndTimeSP(item.end_time);
    setTempOutTime(item.end_time.slice(11,16));
    setEmployeeId(item.employee.id);
    setNoteSP(item.note);
    setSpecialType(item.special_type);
    setSelectedOption({key: item.employee.id, label: item.employee.first_name+' '+item.employee.last_name, value: item.employee.id});
  };

  const selectAdj = (i) => {
    setSelectedAdj(adjustments[i]);
    setSelectedAdj(prevState => ({
      ...prevState,
      index: ""
    }));
    let name = adjustments[i].employee.first_name + ' ' + adjustments[i].employee.last_name
    setSelectedAdj(prevState => ({
      ...prevState,
      employee_name: name
    }));
    setTempNewInTime(adjustments[i].new_in.slice(11,16));
    setTempNewOutTime(adjustments[i].new_out.slice(11,16));
  };

  const clearSelectedPeriod = () => {
    setSelectedWP({});
    setPunchIn("");
    setTempInDate("0000-00-00");
    setTempInTime("00:00");
    setPunchOut("");
    setTempOutDate("0000-00-00");
    setTempOutTime("00:00");
    setInProgress(false);
    setEmployeeId("");
    setUpdateNote("");
    setSelectedOption({});
    setStartTimeSP("");
    setEndTimeSP("");
  };

  const clearSelectedSP = () => {
    setSelectedSP({});
    setStartTimeSP("");
    setTempInDate("0000-00-00");
    setTempInTime("00:00");
    setEndTimeSP("");
    setTempOutTime("00:00");
    setEmployeeId("");
    setNoteSP("");
    setSelectedOption({});
    setSpecialType("");
  };

  const clearSelectedAdj = () => {
    setSelectedAdj({
      index: "", employee: "", employee_name: "", work_period: "", old_in: "",
      old_out: "", new_in: "", new_out: "", old_duration: "", note: "", 
      split: false, in_applied: false, out_applied: false, delete: false
    });
    setTempNewInTime("");
    setTempNewOutTime("");
  };

  const handleGenerate = (e) => {
    e.preventDefault();
    setInReport(true);
    setLoading(true);
    const adjArray = generateReportArray(workPeriods);
    setReportedAdjs(adjArray);
    setLoading(false);
  };

  const handleFishy = (e) => {
    e.preventDefault();
    setInReport(true);
    setLoading(true);
    const adjArray = generateFishyArray(workPeriods);
    setReportedAdjs(adjArray);
    setLoading(false);
  };

  const updateFieldChanged = index => e => {
    let newArr = [...reportedAdjs]; 
    if (e.target.name === "delete") {
      newArr[index]['in_applied'] = false;
      newArr[index]['out_applied'] = false;
      newArr[index][e.target.name] = e.target.checked;
    } else {
      newArr[index]['delete'] = false;
      newArr[index][e.target.name] = e.target.checked;
    };
    setReportedAdjs(newArr);
  };

  const submitAdjustments = () => {
    if (window.confirm("This will make a lot of changes...")){
      setLoading(true);
      client.patch("/api/workperiodsslim/adjust/", reportedAdjs)
      .then((res) => {
        // console.log(res.data);
        lumpAdjust(); 
        setInReport(false);
        startFilter === "0000-00-00 00:00:00" || endFilter === "0000-00-00 00:00:00" ? onJob(true) : apply_filter();
      })
      .catch(function(error) {
        alert("Workperiods/adjust error.");
        console.log('Error', error);
        setInReport(false);
        setLoading(false);
      });
    };
  };

  const lumpAdjust = () => {
    client.post("/api/adjustments/lump_adjust/", reportedAdjs)
    .then((res) => {
      // console.log(res.data);
    })
    .catch(function(error) {
      alert("adjustments/lump_adjust error.");
      console.log('Error', error);
    });
  };

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }
    return true;
  };

  if (user) {
    return (
        <div className="container-fluid mt-3">
        <div className="row">
          {(modelType === 1 && !inReport && !makeSP) && (
            <div className="col-md-3">
            <h3 className="float-left">Work Periods</h3>
              <Form onSubmit={onSubmit} className="mt-2" id="myForm">
                  <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>WorkPeriod #</Form.Label>
                    <Form.Control type="text" disabled={true}
                      value={selectedWP.id || ""}/>
                  </Form.Group>

                <Form.Group className="mb-1" controlId="formSelectOption">
                  <Form.Label>Employee</Form.Label>
                  <Select 
                    label={selectedOption.label}
                    value={selectedOption}
                    isDisabled={Object.keys(selectedWP).length > 0}
                    onChange={e => {setSelectedOption(e); setEmployeeId(e.value);}}
                    options={workers.map(o => ({ key:o.id, label:o.first_name+' '+o.last_name, value:o.id }))}>
                  </Select>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formInProgress">
                  <Form.Label column sm="4">In progress?</Form.Label>
                    <Col sm="3">
                    <Form.Check className="mt-2" type="checkbox" id="default-checkbox"
                        checked={in_progress || false} data-bs-theme="dark"
                        onChange={(e) => setInProgress(e.target.checked)}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempInDate">
                  <Form.Label column sm="3">Start Date</Form.Label>
                    <Col sm="8">
                    <Form.Control type="date" value={tempInDate || ""}
                      onChange={(e) => {setTempInDate(e.target.value); setPunchIn(e.target.value+' '+tempInTime+':00');}}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">Start Time</Form.Label>
                    <Col sm="8">
                    <Form.Control type="time" value={tempInTime || ""}
                      onChange={(e) => {setTempInTime(e.target.value); setPunchIn(tempInDate+' '+e.target.value+':00');}}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempOutDate">
                  <Form.Label column sm="3">End Date</Form.Label>
                    <Col sm="8">
                    <Form.Control type="date" value={tempOutDate || ""}
                      onChange={(e) => {setTempOutDate(e.target.value); setPunchOut(e.target.value+' '+tempOutTime+':00');}}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempOutTime">
                  <Form.Label column sm="3">End Time</Form.Label>
                    <Col sm="8">
                    <Form.Control type="time" value={tempOutTime || ""}
                      onChange={(e) => {setTempOutTime(e.target.value); setPunchOut(tempOutDate+' '+e.target.value+':00');}}/>
                    </Col>
                </Form.Group>

                {!isEmpty(selectedWP) && (
                <Form.Group className="mb-1" controlId="formNote">
                  <Form.Control as="textarea" rows={3} 
                    placeholder="Note (enter your reason for adjusting)"
                    value={updateNote}
                    onChange={(e) => setUpdateNote(e.target.value)}/>
                </Form.Group>
                )}
            
                <div className="float-right">
                {isEmpty(selectedWP) && (
                  <Button size="md" variant="success" type="submit" className="mt-2 mx-2"
                    onClick={onSubmit}>
                      Create
                  </Button>
                )}

                {!isEmpty(selectedWP) && (
                <Button size="md" variant="primary" type="button" className="mt-2 mx-2"
                  onClick={() => {
                    selectedWP.id == null ? window.alert("No work period selected.") : onUpdate(selectedWP.id);
                  }}>
                    Adjust
                </Button>
                )}

                <Button size="md" variant="secondary" type="button" className="mt-2 mx-2"
                  onClick={() => clearSelectedPeriod()}>
                    Clear
                </Button>
                </div>
                <br></br>
                <br></br>
              </Form>
            </div>
          )}

          {(modelType === 2 || inReport) && (
            <div className="col-md-3">
            <h3 className="float-left">Adjustments</h3>
            <Form onSubmit={createWPA} className="mt-2" id="myForm">
                <Row>
                    <Form.Group as={Col} className="mb-1" controlId="formAdjNum">
                        <Form.Label>Adjustment #</Form.Label>
                        <Form.Control
                          type="text"
                          value={selectedAdj.id || ""}
                          disabled={true}
                        />
                    </Form.Group>
                </Row>

                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                  <Form.Label>Employee</Form.Label>
                    <Form.Control
                        type="text"
                        value={selectedAdj.employee_name || ""}
                        disabled={true}
                    />
              </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempOutDate">
                  <Form.Label column sm="3">Date</Form.Label>
                    <Col sm="8">
                      <Form.Control type="date" value={selectedAdj.old_in.slice(0,10) || ""} disabled={true}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">Old In</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={selectedAdj.old_in.slice(11,16) || ""} disabled={true}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">New In</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={tempNewInTime || ""} name="new_in"
                        onChange={(e) => {
                          let daTime = moment(selectedAdj.old_in.slice(0,10)+' '+e.target.value+':00').format("YYYY-MM-DDTHH:mm:ssZ");
                          setSelectedAdj(prevState => (
                            {...prevState, new_in: daTime}));
                            setTempNewInTime(e.target.value);
                          }} 
                        disabled={selectedAdj.index === ""}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">Old Out</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={selectedAdj.old_out.slice(11,16) || ""} disabled={true}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">New Out</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={tempNewOutTime || ""} name="new_out"
                        onChange={(e) => {
                          let disTime = moment(selectedAdj.old_in.slice(0,10)+' '+e.target.value+':00').format("YYYY-MM-DDTHH:mm:ssZ");
                          setSelectedAdj(prevState => (
                            {...prevState, new_out: disTime}));
                            setTempNewOutTime(e.target.value);
                          }}
                        disabled={selectedAdj.index === ""}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="5">Old In Progress</Form.Label>
                    <Col sm="3">
                    <Form.Check className="mt-2" type="checkbox" id="default-checkbox"
                        checked={selectedAdj.old_inProgress || false}
                        disabled={true}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="5">New In Progress</Form.Label>
                    <Col sm="3">
                    <Form.Check className="mt-2" type="checkbox" id="default-checkbox"
                        checked={selectedAdj.new_inProgress || false}
                        disabled={true}/>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-1" controlId="formNote">
                  <Form.Control as="textarea" rows={3} placeholder="Note"
                    value={selectedAdj.note} name="note"
                    onChange={handleChange} disabled={selectedAdj.employee === ""}
                  />
                </Form.Group>
                {inReport && (
                <Button size="md" variant="primary" type="button" className="mx-2"
                  onClick={(e) => {
                    selectedAdj.date === "" ? window.alert("No adjustment selected.") : updateReportedAdjs();
                  }}>
                  Update Adjustment Report
                </Button>
                )}
                {!inReport && (
                <Button size="md" variant="primary" type="button" className="mx-2"
                  onClick={(e) => {
                    selectedAdj.date === "" ? window.alert("No adjustment selected.") : updateWPA();
                  }}>
                  Update Adjustment
                </Button>
                )}
                <Button size="md" variant="secondary" type="button" className="mx-2"
                    onClick={() => clearSelectedAdj()}>
                    Clear
                </Button>
            </Form>
            </div>
          )}

          {((modelType === 3 && !inReport) || makeSP) && (
            <div className="col-md-3">
            <h3 className="float-left">Special Periods</h3>
              <Form className="mt-2" id="myForm">
                <Form.Group className="mb-1" controlId="formBarcodeId">
                  <Form.Label>Specail Period #</Form.Label>
                  <Form.Control type="text" disabled={true}
                    value={selectedSP.id || ""}/>
                </Form.Group>

                <Form.Group as={Col} className="mb-1" controlId="formBarcodeId">
                    <Form.Label>WorkPeriod #</Form.Label>
                    <Form.Control type="text" disabled={true}
                      value={selectedWP.id || ""}/>
                  </Form.Group>

                <Form.Group className="mb-1" controlId="formSelectOption">
                  <Form.Label>Employee</Form.Label>
                  <Select 
                    label={selectedOption.label}
                    value={selectedOption}
                    onChange={e => {setSelectedOption(e); setEmployeeId(e.value);}}
                    options={workers.map(o => ({ key:o.id, label:o.first_name+' '+o.last_name, value:o.id }))}>
                  </Select>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formBarcodeId">
                  <Form.Label>Type</Form.Label>
                  <Form.Control type="text"
                    value={special_type || ""}
                    onChange={(e) => setSpecialType(e.target.value)}/>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempOutDate">
                  <Form.Label column sm="3">Date</Form.Label>
                    <Col sm="8">
                      <Form.Control type="date" value={tempInDate || ""}
                      onChange={(e) => {setTempInDate(e.target.value); setStartTimeSP(e.target.value+' '+tempInTime+':00');}}/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">Start Time</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={tempInTime || ""}
                      onChange={(e) => {setTempInTime(e.target.value); setStartTimeSP(tempInDate+' '+e.target.value+':00');}}/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formTempInTime">
                  <Form.Label column sm="3">End Time</Form.Label>
                    <Col sm="8">
                      <Form.Control type="time" value={tempOutTime || ""} name="new_in"
                        onChange={(e) => {setTempOutTime(e.target.value); setEndTimeSP(tempInDate+' '+e.target.value+':00');}}/>
                    </Col>
                </Form.Group>

                <Form.Group className="mb-1" controlId="formNote">
                  <Form.Control as="textarea" rows={3} 
                    placeholder="Note"
                    value={noteSP}
                    onChange={(e) => setNoteSP(e.target.value)}/>
                </Form.Group>
            
                <div className="float-right">
                {isEmpty(selectedSP) && (
                  <Button size="md" variant="success" type="button" className="mx-2"
                    onClick={() => createSP()}>
                      Create
                  </Button>
                )}

                {!isEmpty(selectedSP) && (
                <Button size="md" variant="primary" type="button" className="mx-2"
                  onClick={() => {
                    selectedWP.id == null ? window.alert("No special period selected.") : updateSP(selectedWP.id);
                  }}>
                    Update
                </Button>
                )}

                <Button size="md" variant="secondary" type="button" className="mx-2"
                  onClick={() => clearSelectedSP()}>
                    Clear
                </Button>
                </div>
                <br></br>
                <br></br>
              </Form>
            </div>
          )}

            <div className="col-md-9 m">
            <div className="row">
            <Form className="mt-0" id="myForm2">
                <Row>
                  <Col className="filter-col">
                    <Form.Group as={Col} className="mb-0" controlId="formFilter">
                    <h4>Filter:</h4>
                    </Form.Group>

                    <Select 
                      label={selectedModel.name}
                      value={selectedModel}
                      onChange={e => {setSelectedModel(e); setModelType(e.value); clearSelectedAdj(); clearSelectedPeriod();}}
                      options={modelOptions.map(o => ({ key:o.num, label:o.name, value:o.num }))}>
                    </Select>
                  </Col>
                    
                    <Col>
                    <Form.Group className="mb-2" controlId="formFnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="First Name"
                            value={searchFname || ""}
                            onChange={(e) => setSearchFname(e.target.value)}/>
                    </Form.Group>

                    <Form.Group className="mb-2" controlId="formLnameFilter">
                        <Form.Control 
                            type="text" 
                            placeholder="Last Name"
                            value={searchLname || ""}
                            onChange={(e) => setSearchLname(e.target.value)}/>
                    </Form.Group>
                    </Col>

                    <Form.Group as={Col} className="mb-2" controlId="formStartFilter">
                        <Form.Label>Start Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={startFilter.slice(0,10) || ""}
                            onChange={(e) => setStartFilter(e.target.value+' '+'00:00:00')}/>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-2" controlId="formEndFilter">
                        <Form.Label>End Date (Clock in)</Form.Label>
                        <Form.Control 
                            type="date" 
                            value={endFilter.slice(0,10) || ""}
                            onChange={(e) => setEndFilter(e.target.value+' '+'23:59:59')}/>
                    </Form.Group>

                    <Col>
                      <Button className="mb-1 mx-1" size="sm" variant="primary" type="button"
                        onClick={(e) => {
                          e.preventDefault(); 
                          apply_filter();}}
                        >
                        Apply Filter
                      </Button>
                    
                      <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }}
                        overlay={clearFilterTT}>
                        <Button className="mb-1 mx-1" size="sm" variant="secondary"
                        type="button"
                        onClick={() => {
                          setStartFilter("0000-00-00 00:00:00"); 
                          setEndFilter("0000-00-00 00:00:00");
                          setSearchFname("");
                          setSearchLname("");
                          onJob(true); 
                          setAdjustments([]);
                          clearSelectedAdj();
                        }}>
                        Remove Filter
                        </Button>
                      </OverlayTrigger>
                    </Col>

                    
                    {(!inReport && modelType === 1 && !makeSP) && (
                    <Col>
                      <DropdownButton id="dropdown-item-button" title="Actions" variant="warning" className="mb-1">
                        {/* <Dropdown.Item as="button" onClick={(e) => {e.preventDefault(); setMakeSP(true); clearSelectedPeriod();}}>Create Special Periods</Dropdown.Item> */}
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={timesheetTT}>
                          <Dropdown.Item as="button" onClick={(e) => {exportTimesheet(e);}}>Download Timesheet</Dropdown.Item>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={absentTT}>
                          <Dropdown.Item as="button" onClick={(e) => {exportAbsencesReport(e);}}>Absences</Dropdown.Item>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={fishyTT}>
                          <Dropdown.Item as="button" onClick={(e) => {handleFishy(e);}}>Fishy Report</Dropdown.Item>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={adjustmentTT}>
                          <Dropdown.Item as="button" onClick={(e) => {handleGenerate(e);}}>Adjustment Report</Dropdown.Item>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={quickbooksTT}>
                          <Dropdown.Item as="button" onClick={(e) => {exportToCSVQB(e);}}>QuickBooks .csv</Dropdown.Item>
                        </OverlayTrigger>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={summaryTT}>
                          <Dropdown.Item as="button" onClick={(e) => {summary(e);}}>Summary .csv</Dropdown.Item>
                        </OverlayTrigger>
                      </DropdownButton>
                    </Col>
                    )}
                    {inReport && (
                    <Col>
                      <Button className="mb-1 mx-1" size="sm" variant="warning" type="button"
                        onClick={(e) => {e.preventDefault(); setInReport(false); 
                          clearSelectedAdj(); setReportedAdjs({});}}>
                      Exit Report
                      </Button>
                      <Button className="mb-1 mx-1" size="sm" variant="danger" type="button"
                        onClick={() => {
                          submitAdjustments();}}>
                      Submit Adjustments
                      </Button>
                    </Col>
                    )}
                    {makeSP && (
                    <Col>
                      <Button className="mb-1 mx-1" size="sm" variant="warning" type="button"
                        onClick={(e) => {e.preventDefault(); setMakeSP(false); clearSelectedPeriod();}}>
                      Exit Special Period Creation
                      </Button>
                    </Col>
                    )}

                </Row>
            </Form>
            </div>
            
            {!inReport &&  modelType === 1 && (
              <div className="tableContainer">
              <table className="table">
                  <thead background_color="gray">
                  <tr>
                      <th scope="col">Employee</th>
                      <th scope="col">In</th>
                      <th scope="col">Out</th>
                      <th scope="col">Duration</th>
                      <th scope="col">In progress?</th>
                      <th scope="col">Department</th>
                      <th scope="col">N = {workPeriods.length}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {!isLoading && workPeriods.map((workPeriod, index) => {
                      return (
                      <tr key={index}>
                          <th>{workPeriod.employee.first_name} {workPeriod.employee.last_name}</th>
                          <td>{workPeriod.punch_in.slice(11,16)} {workPeriod.punch_in.slice(5,7)}/{workPeriod.punch_in.slice(8,10)}</td>
                          <td>{workPeriod.punch_out.slice(11,16)} {workPeriod.punch_out.slice(5,7)}/{workPeriod.punch_out.slice(8,10)}</td>
                          <td>{workPeriod.duration}</td>
                          <td>
                              <input type='checkbox' name='onClock' disabled={true} checked={workPeriod.in_progress}></input>
                          </td>
                          <td>{workPeriod.employee.department}</td>
                          <td>
                              <Button>
                                  <FontAwesomeIcon icon={faSquarePen} 
                                  onClick={() => selectWorkPeriod(workPeriod.id)}/>
                              </Button>&nbsp;
                              {/* <Button variant="danger">
                                  <FontAwesomeIcon icon={faTrash} 
                                  onClick={() => {onDelete(workPeriod.id, workPeriod.employee.id);}}/>
                              </Button> */}
                          </td>
                      </tr>
                      );
                  })}
                  </tbody>
              </table>
              {isLoading && (
                <div id="load" className="center">
                  <h3>loading...</h3>
                </div>
              )}
              </div>
            )}

            {inReport && (
              <div className="tableContainer">
              <table className="table">
                  <thead background_color="gray">
                  <tr>
                      <th scope="col">Employee</th>
                      <th scope="col">Date</th>
                      <th scope="col">In</th>
                      <th scope="col">Out</th>
                      <th scope="col">dt</th>
                      <th scope="col">Note</th>
                      <th scope="col">Actions</th>
                      <th scope="col">N = {reportedAdjs.length}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {!isLoading && reportedAdjs.map((reportedAdj, index) => {
                      return (
                      <tr key={index}>
                          <th>{reportedAdj.employee_name.substring(0, reportedAdj.employee_name.indexOf(' ')+2)}</th>
                          <td>{reportedAdj.old_in.slice(5,7)}/{reportedAdj.old_in.slice(8,10)}</td>
                          <td>{reportedAdj.old_in.slice(11,16)} --&gt; {reportedAdj.new_in.slice(11,16)}</td>
                          <td>{reportedAdj.old_out.slice(11,16)} --&gt; {reportedAdj.new_out.slice(11,16)}</td>
                          <td>{reportedAdj.old_duration}</td>
                          <td>{reportedAdj.note}</td>
                          <td>
                            <table>
                              <tbody>
                                <tr>
                                  <td>In?</td>
                                  <td>
                                    <input type='checkbox' name='in_applied' 
                                      disabled={reportedAdj.new_in === ""} 
                                      checked={reportedAdj.in_applied} 
                                      onChange={updateFieldChanged(index)}></input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Out?</td>
                                  <td>
                                    <input type='checkbox' name='out_applied' 
                                      disabled={reportedAdj.new_out === ""} 
                                      checked={reportedAdj.out_applied} 
                                      onChange={updateFieldChanged(index)}></input>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Delete?</td>
                                  <td>
                                    <input type='checkbox' name='delete' 
                                      disabled={!reportedAdj.note.toLowerCase().includes("delete")}
                                      checked={reportedAdj.delete} 
                                      onChange={updateFieldChanged(index)}></input>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td>
                              <Button>
                                  <FontAwesomeIcon icon={faSquarePen} 
                                  onClick={() => selectAdjRec(index)}/>
                              </Button>
                          </td>
                      </tr>
                      );
                  })}
                  </tbody>
              </table>
              {isLoading && (
                <div id="load" className="center">
                  <h3>loading...</h3>
                </div>
              )}
              </div>
            )}

            {(!inReport && modelType === 2) && (
              <div className="tableContainer">
              <table className="table">
                  <thead background_color="gray">
                  <tr>
                      <th scope="col">Employee</th>
                      <th scope="col">Date</th>
                      <th scope="col">In</th>
                      <th scope="col">Out</th>
                      <th scope="col">Note</th>
                      <th scope="col">N = {adjustments.length}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {!isLoading && adjustments.map((adjustment, index) => {
                      return (
                      <tr key={index}>
                          <th>{adjustment.employee.first_name} {adjustment.employee.last_name}</th>
                          <td>{adjustment.old_in.slice(5,7)}/{adjustment.old_in.slice(8,10)}</td>
                          <td>{adjustment.old_in.slice(11,16)} --&gt; {adjustment.new_in.slice(11,16)}</td>
                          <td>{adjustment.old_out.slice(11,16)} --&gt; {adjustment.new_out.slice(11,16)}</td>
                          <td>{adjustment.note}</td>
                          <td>
                              <Button>
                                  <FontAwesomeIcon icon={faSquarePen} 
                                  onClick={() => selectAdj(index)}/>
                              </Button>
                          </td>
                      </tr>
                      );
                  })}
                  </tbody>
              </table>
              {isLoading && (
                <div id="load" className="center">
                  <h3>loading...</h3>
                </div>
              )}
              </div>
            )}

            </div>
        </div>
        </div>
    );
  } else {
    return (
      <div>
          Access denied.
      </div>
    );
  };
};

export default AddWorkPeriod;