// Simple page for clocking in/out with auto-cursor for scanning
import React from 'react';
import { useState, useEffect } from 'react';
import { Row } from "react-bootstrap";
import Clock from 'react-live-clock';
import moment from 'moment';


const TimeClock = ({ client, user, userInfo }) => {

  const [workerId, setWorkerId] = useState("");
  const [workers, setWorkers] = useState([]);
  const [clockingLoading, setClockingLoading]=useState(false);
  const [justClockedIn, setJustClockedIn] = useState({});
  const [justClockedOut, setJustClockedOut] = useState({});
  const [inProgPeriods, setInProgPeriods] = useState([]);
  const [inProgPeriodsByUser, setInProgPeriodsByUser] = useState([]);
  const [color, setColor] = useState("black");
  const [timeoutId, setTimeoutId] = useState(undefined);
  const [workersOut, setWorkersOut] = useState([]);

  var perIndex = -1;
  var empId = -1;
  var lat = 0.0;
  var long = 0.0;

  useEffect(() => {
    refreshAt(5,1,0);
    getActive();
    onJob();
    locate();
  }, [user]);

  useEffect(() => {
    if (workers.length > 0 && userInfo.user_level < 9){
      getOutWorkers();
    };
  }, [inProgPeriods])

  const getOutWorkers = () => {
    let out = workers.filter((w) => {
      let found = inProgPeriods.find((p) => p.employee.id === w.id);
      if (found) {
        return false;
      } else {
        return true;
      };
    });
    out.sort(function(a,b){
      let x = a.department.toLowerCase();
      let y = b.department.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    setWorkersOut(out);
  };

  function refreshAt(hours, minutes, seconds) {
    var now = new Date();
    var then = new Date();

    if(now.getHours() > hours ||
       (now.getHours() == hours && now.getMinutes() > minutes) ||
        now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds) {
        then.setDate(now.getDate() + 1);
    }
    then.setHours(hours);
    then.setMinutes(minutes);
    then.setSeconds(seconds);

    var timeout = (then.getTime() - now.getTime());
    setTimeout(function() { window.location.reload(true); }, timeout);
};

  const locate = () => {
    navigator.geolocation.getCurrentPosition(function(position) {
      lat = Math.round(position.coords.latitude * 1000000)/1000000;
      long = Math.round(position.coords.longitude * 1000000)/1000000;
    });
  };

  const onJob = () => {
    setClockingLoading(true);
    client.get("/api/workperiods/on_job")
      .then((res) => {
        setInProgPeriods(res.data);
        let byUser = res.data.filter((datum) => datum.created_by === userInfo.user_id);
        setInProgPeriodsByUser(byUser);
        setClockingLoading(false);
      })
      .catch(function(error) {
        console.log("Workperiods/on_job access denied. Check connection.");
        setClockingLoading(false);
      });
  };

  const getActive = () => {
    if (userInfo.user_level > 0) {
      client.get("/api/employees/get_active/")
      .then((res) => {
        setWorkers(res.data);
      })
      .catch(function(error) {
        console.log("employees/get_active access denied. Check connection.");
      });
    };
  };

  const clockOut = (event) => {
    let in_progress = false;
    let punch_out_moment = moment().second(0);
    let punch_out = moment(punch_out_moment).format("YYYY-MM-DDTHH:mm:ssZ");
    let updated_by = userInfo.user_id;
    let up_lat = lat;
    let up_long = long;
    let item = { in_progress, punch_out, updated_by, up_lat, up_long };
    client.patch(`/api/workperiods/${perIndex}/`, item)
    .then((res) => {
      let indx = inProgPeriods.findIndex((per) => per.id === perIndex);
      let inProg = [...inProgPeriods]; 
      inProg.splice(indx, 1);
      setInProgPeriods(inProg);
      let byUser = inProg.filter((datum) => datum.created_by === userInfo.user_id);
      setInProgPeriodsByUser(byUser);
      setJustClockedOut(res.data);
      setClockingLoading(false);
      setWorkerId("");
      event.target.reset();
      clearTimeout(timeoutId);
      setColor("red");
      let id = setTimeout(() =>{
        setJustClockedOut({});
        setColor("black");
      }, 2500);
      setTimeoutId(id);
    })
    .catch(function(error) {
      alert("Clock out denied. Check internet connection.");
      setClockingLoading(false);
      setWorkerId("");
      event.target.reset();
    });
  };

  const clockIn = (event) => {
    let employee = empId;
    let punch_in_moment = moment().second(0);
    let punch_in = moment(punch_in_moment).format("YYYY-MM-DDTHH:mm:ssZ");
    let punch_out_moment = moment().second(0);
    let punch_out = moment(punch_out_moment).format("YYYY-MM-DDTHH:mm:ssZ");
    let created_by = userInfo.user_id;
    let cr_lat = lat;
    let cr_long = long;
    let item = { employee, punch_in, punch_out, created_by, cr_lat, cr_long };
    client.post("/api/workperiods/", item)
    .then((res) => {
      let inProg = [...inProgPeriods]; 
      inProg.unshift(res.data);
      setInProgPeriods(inProg);
      let byUser = inProg.filter((datum) => datum.created_by === userInfo.user_id);
      setInProgPeriodsByUser(byUser);
      setJustClockedIn(res.data);
      setClockingLoading(false);
      setWorkerId("");
      event.target.reset();
      clearTimeout(timeoutId);
      setColor("blue");
      let id = setTimeout(() =>{
        setJustClockedIn({});
        setColor("black");
      }, 2500);
      setTimeoutId(id);
    })
    .catch(function(error) {
        alert("Clock in denied. Check internet connection.");
        setClockingLoading(false);
        setWorkerId("");
        event.target.reset();
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setClockingLoading(true);
    setColor("black");
    setJustClockedIn({});
    setJustClockedOut({});
    var int_id = parseInt(workerId, 10);
    let inProg = {};

    if (isNaN(workerId) || workerId == "") {
      alert("Bad input: try again.");
      setClockingLoading(false);
      setWorkerId("");
      event.target.reset();
      return;
    };
    let found = workers.find((worker) => (worker.barcode_id === int_id || worker.rfid === int_id));
    if (!found) {
      alert("Active employee not found. Change active status before clocking in, or try a different number.");
      setClockingLoading(false);
      setWorkerId("");
      event.target.reset();
      return;
    };
    if (workerId.length <= 4) {
      inProg = inProgPeriods.filter((inProgPeriod) => inProgPeriod.employee.barcode_id === int_id)[0];
      empId = workers.filter((worker) => worker.barcode_id === int_id)[0].id;
    } else {
      inProg = inProgPeriods.filter((inProgPeriod) => inProgPeriod.employee.rfid === int_id)[0];
      empId = workers.filter((worker) => worker.rfid === int_id)[0].id;
    };
    if (inProg === undefined) {
      clockIn(event);
    } else {
      perIndex = inProg.id;
      clockOut(event);
    };
  };

  if (user && userInfo.user_level > 0) {
    return (
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6 m">
            <Row>
              <h1>
                <Clock className='center' format="h:mm:ss A" interval={1000} ticking={true} timezone='America/Chicago' />
              </h1>
            </Row>
            <Row>
              <h3>
                <Clock className='center' format="dddd, MMMM D, YYYY" timezone='America/Chicago' />
              </h3>
            </Row>
            <Row>
              <form className='center' onSubmit={handleSubmit} id="myform">
              <br></br>
                <label>Worker ID:&nbsp;
                  {!clockingLoading && (
                  <input autoFocus type="text"
                    value={workerId}
                    onChange={(e) => setWorkerId(e.target.value)}/>
                  )}
                  {clockingLoading && (
                    <input autoFocus type="text"
                    value={workerId + " searching..."}
                    disabled={true}
                    />
                  )}
                </label>
                <input type="submit" />
              </form>
            </Row>
            <br></br>
            <Row>
              <div style={{background:color, color:"white", textAlign:"center"}}>
                {(Object.keys(justClockedIn).length > 0 && !clockingLoading) && (
                  <h1>{justClockedIn.employee.first_name + " -- IN"}</h1>
                )}
                {(Object.keys(justClockedOut).length > 0 && !clockingLoading) && (
                  <h1>{justClockedOut.employee.first_name + " -- OUT"}</h1>
                )}
                {(Object.keys(justClockedIn).length === 0 && Object.keys(justClockedOut).length === 0  && !clockingLoading) && (
                  <h1>&nbsp;</h1>
                )}
                {clockingLoading > 0 && (
                  <h1>searching...</h1>
                )}
              </div>
            </Row>
          </div>
          
          <div className="col-md-6 m">
          {userInfo.user_level < 9 && (
            <div className="tableContainer">
              <table className="table">
                <thead background_color="gray">
                  <tr>
                    <th scope="col">Clocked in = {inProgPeriods.length}</th>
                  </tr>
                </thead>
                <tbody>
                  {inProgPeriods.map((per, index) => {
                    return (
                      <tr key={index}>
                        <td>{per.employee.first_name} {per.employee.last_name} @ {per.punch_in.slice(11,16)} {per.punch_in.slice(5,7)}/{per.punch_in.slice(8,10)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <br></br>
          {userInfo.user_level < 9 && (
            <div className="tableContainer">
              <table className="table">
                <thead background_color="gray">
                  <tr>
                    <th scope="col">Clocked out = {workersOut.length}</th>
                  </tr>
                </thead>
                <tbody>
                  {workersOut.map((w, index) => {
                    return (
                      <tr key={index}>
                        <td>{w.first_name} {w.last_name} -- {w.department}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {userInfo.user_level === 9 && (
            <div className="tableContainer">
              <table className="table">
                <thead background_color="gray">
                  <tr>
                    <th scope="col">Clocked in at {userInfo.username} = {inProgPeriodsByUser.length}</th>
                  </tr>
                </thead>
                <tbody>
                  {inProgPeriodsByUser.map((per, index) => {
                    return (
                      <tr key={index}>
                        <td>{per.employee.first_name} {per.employee.last_name} @ {per.punch_in.slice(11,16)} {per.punch_in.slice(5,7)}/{per.punch_in.slice(8,10)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-6 m">
          <Row>
            <h1>
              <Clock className='center' format="h:mm:ss A" interval={1000} ticking={true} timezone='America/Chicago' />
            </h1>
          </Row>
          <Row>
            <h3>
              <Clock className='center' format="dddd, MMMM D, YYYY" timezone='America/Chicago' />
            </h3>
          </Row>
        </div>
      </div>
    </div>
  );
};
  
export default TimeClock;

