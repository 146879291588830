import moment from 'moment';

export function generateFishyArray (data) {

    const reportArray = [];

    for (const row of data) {
        let oldin = moment(row["punch_in"]);
        let oldout = moment(row["punch_out"]);
        let newin = moment(oldin);
        let newout = moment(oldout);
        let adj = {
            employee: row["employee"]["id"],
            employee_name: row["employee"]["first_name"] + " " + row["employee"]["last_name"],
            work_period: row["id"],
            old_in: moment(oldin).format("YYYY-MM-DDTHH:mm:ssZ"),
            old_out: moment(oldout).format("YYYY-MM-DDTHH:mm:ssZ"),
            new_in: "",
            new_out: "",
            old_duration: row["duration"],
            note: "",
            in_applied: false,
            out_applied: false,
            delete: false
        };
        
        if (row["in_progress"]) {
            continue;
        }

        let empRows = data.filter((r) => r.employee.id === row["employee"]["id"]);
        let empRowsOther = empRows.filter((r) => r.id != row["id"]);
        for (const oRow of empRowsOther) {
            if (oRow["punch_in"] === row["punch_out"] || oRow["punch_out"] === row["punch_in"]) {
                adj.note += "Overlap?--"
            }
        }

        let totDur = 0;
        for (const dRow of empRows) {
            totDur += dRow['duration'];
        }
        if (totDur < 7) {
            adj.note += "Undertime?--"
        }
        if (adj.old_duration <= 0.25) {
            adj.note += "delete?--"
        }  
        if (oldout.hour() === 12 && adj.old_duration > 0.25 && oldout.minute() >= 10) {
            adj.note += "FailedToClockOutCorrectlyForLunch?--"
        } 
        if (oldout.hour() === 12 && adj.old_duration > 0.15 && adj.old_duration < 1) {
            adj.note += "FailedToClockOutCorrectlyForLunch?--"
        }
        if (row["duration"] > 6.5) {
            adj.note += "Overtime?--"
        } 
        if (oldin.hour() < 12 && oldout.hour() > 12) {
            adj.note += "FailedToClockOutForLunch?--"
        } 
        if (oldout.hour() > 20) {
            adj.note += "FailedToClockOutAtEndOfDay?--"
        }

        if (adj.note !== "") {
            reportArray.push(adj);
        };
    }
    return reportArray;
}



