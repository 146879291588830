import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import TimeClock from "./TimeClock";
import Login from "./Login";
import NoPage from "./NoPage";
import Home from "./Home"
import WorkPeriods from "./WorkPeriods";
import Workforce from "./Workforce";
import Admin from "./Admin";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "https://api.addisonop.com"
  // baseURL: "http://127.0.0.1:8000/"
});

export default function App() {

  const [user, setCurrentUser] = useState(false);
  const [userInfo, setCurrentUserInfo] = useState({});

  useEffect(() => {
    client.get("/user_api/user")
    .then(function(res) {
      setCurrentUser(current => true);
      setCurrentUserInfo(res.data.user);
    })
    .catch(function(error) {
      setCurrentUser(current => false);
      setCurrentUserInfo({});
    });
  }, [user]);

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout user={user}
                                    userInfo = {userInfo} 
                                    setCurrentUser={setCurrentUser}
                                    client={client}/>}>
            <Route index element={<Home />} />
            <Route path="log" element={<Login user={user} 
                                        setCurrentUser={setCurrentUser}
                                        client={client}>
              </Login>} />
            <Route path="timeclock" element={<TimeClock client={client} user={user} userInfo = {userInfo} />} />
            <Route path="workforce" element={<Workforce client={client} user={user} userInfo = {userInfo} />} />
            <Route path="workperiods" element={<WorkPeriods client={client} user={user} userInfo = {userInfo} />} />
            <Route path="administration" element={<Admin client={client} user={user} userInfo = {userInfo} />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
